import {Route} from 'react-router-dom';
import {IonReactRouter} from '@ionic/react-router';
import {useDispatch, useSelector} from 'react-redux';
import {
    IonButton,
    IonButtons, IonContent,
    IonHeader,
    IonModal,
    IonRouterOutlet,
    IonTitle,
    IonToolbar,
    isPlatform
} from '@ionic/react';
import Connexion from '../components/Formulaire/Connexion/Connexion';
import Register from '../components/Formulaire/Register/Register';
import {SplashScreen} from "@capacitor/splash-screen";
import {StatusBar, Style} from "@capacitor/status-bar";
import Connect from '../components/Formulaire/FormContent/StravaConnect/Connect';

import FormSuccess from '../components/Formulaire/FormContent/FormSuccess/FormSuccess';
import HowManyTimes from '../components/Formulaire/FormContent/FormDisponibility/content/HowManyTimes';
import WeekBilanSuccess from '../components/App/NewPlan/WeekBilan/WeekBilanSuccess';

import TabBar from '../pages/TabBar';
import StravaSuccess from '../components/Formulaire/FormContent/StravaConnect/StravaSuccess';
import FormDisponibility from '../components/Formulaire/FormContent/FormDisponibility/content/FormDisponibility';
import Objectif from '../components/Formulaire/FormContent/Objectif/Objectif';
import ErrorWeekPlan from '../components/App/Home/ErrorWeekPlan/ErrorWeekPlan';
import {Redirect, useHistory} from "react-router";
import React, {useEffect, useState} from "react";
import {ChangeLogin} from "../redux/slices/currentUser/currentUserSlice";
import {isEmpty} from "../Utils/Utils";
import {Capacitor} from "@capacitor/core";
import NoviceLevel from "../components/Formulaire/FormContent/Novice/NoviceLevel/NoviceLevel";
import Question1 from "../components/Formulaire/FormContent/Novice/NoviceLevel/AlreadyRunner/content/Question1";
import ObjectifInter from "../components/Formulaire/FormContent/Objectif/ObjectifInter/ObjectifInter";
import axios from "axios";

import GarminSuccess from "../components/Formulaire/FormContent/StravaConnect/GarminSuccess";
import {IsVipOrNot} from "../API/User/VipOrNot";


function Routes() {
    const isLoggin = useSelector((state) => state.login.isLoggin);
    const isRegister = useSelector((state) => state.login.isRegister);
    const user = useSelector((state => state.currentUser))
    const dispatch = useDispatch()
    const history = useHistory()
    const [isOpen, setIsOpen] = useState(false)
    const currentUser = useSelector(state => state.currentUser)
    const checkUser = () => {
        if (currentUser.id !== undefined || !isEmpty(currentUser)) {
            axios.post(`${process.env.REACT_APP_STAGING_V2}/user/validated`, {
                debug: false, data: {
                    userId: currentUser.id
                }, arg: {}
            }).then(res => {

                if (res.data.Data[0].disponibility === true) {
                    history.push("/tabs/tab1")
                    setTimeout(() => {
                        SplashScreen.hide()

                    }, 1000)
                } else {
                    history.push("/register")
                    setTimeout(() => {
                        SplashScreen.hide()

                    }, 1000)
                }

            }).catch(e => {
                history.push("/register")
                setTimeout(() => {
                    SplashScreen.hide()
                }, 1000)
            })
        } else {
            history.push("/register")
            setTimeout(() => {
                SplashScreen.hide()

            }, 1000)
        }

    }


    // useEffect(() => {
    //
    //
    //
    //     checkUser();
    //
    //
    // }, [])

    // @ts-ignore

    SplashScreen.hide()
    return (
        <>
            <IonModal isOpen={isOpen}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Modal</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent className="ion-padding">
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni illum quidem recusandae ducimus
                        quos
                        reprehenderit. Veniam, molestias quos, dolorum consequuntur nisi deserunt omnis id illo sit cum
                        qui.
                        Eaque, dicta.
                    </p>
                </IonContent>
            </IonModal>
            <IonRouterOutlet
                animated={true}
            >
                <Route path={"/"} exact component={Register}/>
                <Route path="/connexion" exact component={Connexion}/>
                <Route path="/register" component={Register}/>
                <Route path="/typeofrun" exact component={Objectif}/>
                <Route path="/objectifInter" exact component={ObjectifInter}/>
                <Route path="/connect" exact component={Connect}/>

                <TabBar/>
                <Route path="/stravaSuccess" exact component={StravaSuccess}/>
                <Route path="/garminSuccess" exact component={GarminSuccess}/>


                {/*Novice*/}
                <Route path="/noviceLevel" exact component={NoviceLevel}/>
                <Route path="/noviceLevel/question1" exact component={Question1}/>


                <Route path="/disponibilityWeek" exact component={HowManyTimes}/>
                <Route path="/disponibilityForm" exact component={FormDisponibility}/>
                <Route path="/weekBilanSuccess" exact component={WeekBilanSuccess}/>


            </IonRouterOutlet>
        </>
    );
}

export default Routes;
