import axios from "axios"
import {GetisVip} from "../../redux/slices/currentUser/currentUserSlice";
import moment from "moment";
import ModalNotVip from "../../components/Reusable/ModalNotVip";
import {useState} from "react";
import {ApiError} from "../Error/ApiError";


// APi calls for check the VIP status
export const IsVipOrNot = (data) => async (dispatch) => {
     axios.post(`${process.env.REACT_APP_STAGING_V2}/payment/verifyPayment`, {
        debug: false, data: {
            userId: data.id,
        },
    }, {
        headers: {
            Authorization: data.token,
        }
    }).then(res => {

        //  status = 0, it's not subscribe
        //  status = 1, it's subscriber in trial period
        //  status = 2, is VIP
        if (res.data.Data[0] === true) {
            dispatch(GetisVip({isVip: true, isTrialPeriod: false}))
        }
        if (res.data.Data[0] === false) {
            dispatch(GetisVip({isVip: false, isTrialPeriod: false}))
        }



    }).catch(e => console.log(e)
    )
}